import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDnHaKBzT3yfCeeBdHzGq-yw_QwYxnK7nw",
  authDomain: "trimbleconnecthu.firebaseapp.com",
  projectId: "trimbleconnecthu",
  storageBucket: "trimbleconnecthu.appspot.com",
  messagingSenderId: "345499691081",
  appId: "1:345499691081:web:991a85a1b438012ad57798",
  measurementId: "G-E9QNVGEX6K"
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);