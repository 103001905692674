import Vue from 'vue'
import VueRouter from 'vue-router'

import LoadScript from 'vue-plugin-load-script'
Vue.use(LoadScript)

import Fragment from 'vue-fragment'
Vue.use(Fragment.Plugin)

import App from './App.vue'

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

// import * as workspace from 'workspace-api'
// Vue.use(workspace)

import VuePageTitle from 'vue-page-title'
Vue.use(VuePageTitle, {
  suffix: '| Trimble Connect'
})

import Clipboard from 'v-clipboard'
Vue.use(Clipboard)

import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"

const options = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true
}

Vue.use(Toast, options);

import '@/firebase/config.js'
import '@/css/style.scss';

import { routes } from './routes.js'

Vue.use(VueRouter)

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior(to) {
    return new Promise(() => {
      if (to.hash) {
        window.scroll({ top: 0 })
      } else {
        document.querySelector('html').style.scrollBehavior = 'auto'
        window.scroll({ top: 0 })
        document.querySelector('html').style.scrollBehavior = ''
      }
    })
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
