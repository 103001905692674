<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import AOS from 'aos'
import Sticky from 'sticky-js'
import { focusHandling } from 'cruip-js-toolkit'
// import CookiesComponent from '@/utils/Cookies.vue'

export default {
  name: 'App',
  mounted() {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 700,
      easing: 'ease-out-cubic',
    })
    // eslint-disable-next-line no-unused-vars
    const sticky = new Sticky('[data-sticky]');
    focusHandling()
    // Route change
    if (this.$router) {
      this.$watch('$route', () => {
        // eslint-disable-next-line no-unused-vars
        const sticky = new Sticky('[data-sticky]');
        focusHandling('outline')
      });
    }

    // if(localStorage.getItem('coooooookiiiiesss') !== 'ok') {
    //   this.$toast(CookiesComponent, {
    //     position: "bottom-left",
    //     timeout: 0,
    //     closeOnClick: false,
    //     pauseOnFocusLoss: false,
    //     pauseOnHover: false,
    //     draggable: false,
    //     draggablePercent: 0.6,
    //     showCloseButtonOnHover: false,
    //     hideProgressBar: true,
    //     closeButton: false
    //   })
    // }
  }
}
</script>
