export const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "Domů" */ './pages/Home.vue')
  },
  {
    path: '/kapcsolat',
    component: () => import(/* webpackChunkName: "Kontakt" */ './pages/Contact.vue')
  },
  {
    path: '/egyuttmukodes',
    component: () => import(/* webpackChunkName: "Propojení" */ './pages/Propojeni.vue')
  },
  {
    path: '/arak',
    component: () => import(/* webpackChunkName: "Ceník" */ './pages/Cenik.vue')
  },
  // {
  //   path: '/faq/:q',
  //   component: () => import(/* webpackChunkName: "FAQ Direct" */ './pages/Documentation.vue')
  // },
  // {
  //   path: '/faq',
  //   component: () => import(/* webpackChunkName: "FAQ" */ './pages/Documentation.vue'),
  // },
  {
    path: '/referencia',
    component: () => import(/* webpackChunkName: "Reference" */ './pages/Reference.vue')
  },
  {
    path: '/demo',
    component: () => import(/* webpackChunkName: "Demo" */ './pages/Demo.vue')
  },
  {
    path: '/miert-trimble-connect',
    component: () => import(/* webpackChunkName: "Proč Trimble Connect" */ './pages/About.vue')
  },  
  // {
  //   path: '/webinare',
  //   component: () => import(/* webpackChunkName: "Proč Trimble Connect" */ './pages/Webinare.vue')
  // },          
  {
    path: '*',
    component: () => import(/* webpackChunkName: "404" */ './pages/PageNotFound.vue')
  }
]